// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // devlepment
  apiUrl: 'https://devapi.bando-app.com/v1/admin/',
  apiBaseUrl: 'https://devapi.bando-app.com/v1/',
  // stagging
  // apiUrl: ' https://stagapi.bando-app.com/v1/admin/',
  // apiBaseUrl: ' https://stagapi.bando-app.com/v1/',
  // prod
  // apiUrl: 'https://api.bando-app.com/v1/admin/',
  // apiBaseUrl: 'https://api.bando-app.com/v1/',
  firebase: {
    apiKey: "AIzaSyAb4Y49NLWrg9Ja0rpXAM6e5LX-DrM2LNg",
    authDomain: "bando-creators.firebaseapp.com",
    databaseURL: "https://bando-creators-default-rtdb.firebaseio.com",
    projectId: "bando-creators",
    storageBucket: "bando-creators.appspot.com",
    messagingSenderId: "87096617327",
    appId: "1:87096617327:web:dc206880a52da7c39b609a",
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
