import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router'
import { map, catchError} from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
// import { ToastrService } from "ngx-toastr";
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../services/storage.service';
import { environment as ENV } from '../../environments/environment';
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {

    constructor(
        private storageService: StorageService,
        private route: Router,public toastr : ToastrService,
        private modelService: NgbModal
    ){ }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: string = this.storageService.getData('bandoAccessToken');
        // console.log(token)
        if (token) {
            request = request.clone({ headers: request.headers.set('Authorization', token) });
        }
        if (!request.headers.has('Content-Type') && request.url!==ENV.apiBaseUrl+'artist/uploadFile') {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }
        if (!request.headers.has('Accept')) {
            request= request.clone({ headers: request.headers.set('Accept', '*/*') });
        }
        if(!request.headers.has('timezone')){
            request= request.clone({headers: request.headers.set('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone)})
        }
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // console.log('event--->>>', event);
                }
                return event;
            }),
            catchError((error: any) => {
                if(error.status==401 || error.status==402){
                    // alert("Session Expired")
                    this.modelService.dismissAll();
                    this.toastr.error('Session Expired');
                    this.route.navigate(['login'])
                }
                return throwError(error);
            })
        );
    }
}