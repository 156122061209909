import {Injectable} from '@angular/core';
import {AngularFireMessaging} from '@angular/fire/messaging';
import {BehaviorSubject} from 'rxjs';
import { StorageService } from './storage.service';
import { ToastrService } from 'ngx-toastr';
@Injectable()
export class MessagingService {
    x:any[]=[];
    mess:any
    private count = new BehaviorSubject<any>({
    });
    currentMessage = new BehaviorSubject(null);
    crrmessage=new BehaviorSubject(null);
    constructor(private angularFireMessaging : AngularFireMessaging,private storageService: StorageService,public toastr: ToastrService) {
        this.angularFireMessaging.messages.subscribe((_messaging: AngularFireMessaging) => {
            _messaging.onMessage = _messaging.onMessage.bind(_messaging);
            _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
        })
    }
    requestPermission() {
        this.angularFireMessaging.requestToken.subscribe((token) => {
            // console.log(token);
            this.storageService.setData('fcmToken', token)
        }, (err) => {
            console.error('Unable to get permission to notify.', err);
        });
    }
    receiveMessage() {
        this.angularFireMessaging.messages.subscribe((payload) => {
            console.log(payload)
            this.mess=payload;
            this.mess=this.mess.notification.body
            this.toastr.success(this.mess)
            this.currentMessage.next(payload);
            this.setBookingspots(payload)
            console.log(this.currentMessage)
        })
    }
    setBookingspots(spots: any) {
        this.count.next(spots);
      }
    
      getBookingspots() {
        return this.count.asObservable();
      }
}
