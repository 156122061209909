import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn:"root"
})

export class StorageService {

  private date = new Subject();

  constructor(
  ) { }

  getData(key: string): any {
    return localStorage.getItem(key);
  }

  setData(key: string, data: any) {
    localStorage.setItem(key, data);
  }

  removeData(key: string, data: any) {
    localStorage.removeItem(key);
  }

  clearData(){
    localStorage.clear();
  }

  getDate(){
    return this.date.asObservable();
  }

  setDate(val){
    this.date.next(val)
  }

}