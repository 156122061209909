import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
  path: '',
  loadChildren: () => import(`./auth/auth.module`).then(m => m.AuthModule),
  },
  {
    path: '',
    loadChildren: () => import(`./layouts/default/default.module`).then(m => m.DefaultModule),
  },
{
  path: "**",
  redirectTo: "login",
  pathMatch: 'full'
}
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
